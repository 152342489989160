body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}

 .modal-overlay {
  position: 'fixed';
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;

  background-color: rgba(0, 0, 0, 0.7);
  z-index: 1000;
}

/* background-color: 'rgba(0,0,0, 0.90)'; */
.modal-content {

  position: 'absolute';
  top: '5px';
  left: '5px';
  right: '5px';
  bottom: '5px';
  border: '5px solid #ccc';
  background: '#fff';
  overflow: 'auto';
  WebkitOverflowScrolling: 'touch';
  borderRadius: '4px';
  outline: 'none';
  padding: '20px';
  width: 1000px; 
} 

/* .modal-overlay {
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background-color: rgba(0, 0, 0, 0.5);
  display: flex;
  align-items: center;
  justify-content: center;
}

.modal-content {

  top: 0;
  left: 0;
  width:60%;
  height:60%;

  background: white;
  padding: 20px;
  border-radius: 5px;
  width: 400px; */
   



  .item-grid {
    display: grid;
    grid-template-columns: repeat(3, 1fr); /* 3 columns */
    gap: 5px; /* Space between items */
  }
  
  @media (max-width: 768px) {
    .item-grid {
      grid-template-columns: repeat(2, 1fr); /* 2 columns on smaller screens */
    }
  }
  
  @media (max-width: 480px) {
    .item-grid {
      grid-template-columns: 1fr; /* 1 column on extra small screens */
    }
  }

  .cart-icon-container {
    position: relative;
    display: inline-block;
  }
  
  .cart-icon {
    width: 30px; /* Adjust size as needed */
    height: auto;
    
  }
  
  .cart-badge {
    position: absolute;
    top: -5px; /* Adjust position */
    right: -10px; /* Adjust position */
    background-color: red;
    color: white;
    border-radius: 50%;
    padding: 5px 8px;
    font-size: 12px; /* Small size */
  }
  

  button:hover {
    background-color: #0056b3;
  }